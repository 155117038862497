<template>
    <div>
        <b-modal
            id="modal-locker-not-achieved"
            size="sm"
            v-model="isOpen"
            centered
            hide-header
            :no-close-on-backdrop="true"
        >
        <b-card-header class="banned-background-color">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0 text-danger">
              {{ $t('golf_update_locker_not_achieved') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="rgba(234, 84, 85, 1)"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
            <!-- <b-row class="border-bottom">
                <b-col cols="12">
                    <b-form-group>
                        <b-form-input
                            :placeholder="$t('golf_locker_inventory_input_search_reason')"
                        />
                    </b-form-group>
                </b-col>
            </b-row> -->
            <b-row class="border-bottom list-reason">
                <b-col cols="12" v-for="(item, index) in listReason" :key="index" class="d-flex">
                    <b-form-checkbox v-model="item.check" :state="false" class="mt-1 mb-1">
                        <h5 class="ml-1">
                            {{ item.AuditResult }}
                        </h5>
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="12">
                    <b-form-group>
                        <b-form-textarea v-model="note" :placeholder="$t('golf_locker_inventory_input_note')" class="mb-0" rows="3"
                            max-rows="6"/>
                    </b-form-group>
                </b-col>
            </b-row>

        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="danger"
                    size="md"
                    class="float-right"
                    @click="saveUpdateNotAchieved"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                size="md"
                variant="none"
                class="float-right mr-1"
                @click="hideModal"
                >
                    {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
        <updateStatusMaintenance :dateSelected="dateSelect" :lockerSelected="lockerSelect" @event="handEvent"  />
    </div>
</template>
<script>
import { lockerSetting } from '@/api/locker-setting'
import updateStatusMaintenance from './updateStatusMaintenance.vue'
export default {
    props: ['dateSelect', 'lockerSelect'],
    components: {
        updateStatusMaintenance
    },
    data() {
        return {
            isOpen: false,
            listReason: [],
            note: null
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-locker-not-achieved')
            this.listReason.forEach(x => {
                x.check = false
            })
        },
        async getListReason_RLAR01() {
            await lockerSetting.getListReason().then(res => {
                if(res.Status == 200) {
                    this.listReason = res.Data.LockerAuditResult
                    this.listReason.forEach(x => {
                        x.check = false
                    })
                }
            })
        },
        async saveUpdateNotAchieved() {
            if(this.listReason.filter(x => x.check).length > 0) {
                if(this.listReason.filter(x => x.check && x.Action == 'MAINTENANCE').length > 0) {
                    this.$bvModal.show('modal-locker-add-plan-maintenance')
                } else {
                    this.call_api_update()
                }
            } else {
                this.showToast('error', this.$t('golf_locker_please_select_reason'))
            }
        },
        handEvent(data) {
            if(data.type == 'after-update-status-locker') {
                this.call_api_update()
            }
        },
        async call_api_update() {
            const body = {
                AuditDate: this.dateSelect,
                LockerAuditResultId: this.listReason.filter(x => x.check).map(y => y.Id),
                LockerId: this.lockerSelect.map(x => x.LockerId),
                Note: this.note,
            }
            await lockerSetting.updateLockerInventory(body).then(res => {
                this.showResToast(res)
                    if(res.Status == '200') {
                        this.hideModal()
                        this.$emit('event', { type: 'after-update_not_achieved' })
                    }
            })
        }
    },
    created() {
        this.getListReason_RLAR01()
    },
}
</script>
<style lang="scss">
@import '@/assets/scss/variables/variables';
.banned-background-color {
    background-color: rgba(234, 84, 85, 0.08);
}
.list-reason {
    max-height: 200px;
    overflow-y: auto;
}
</style>