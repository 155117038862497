/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
    ROOT,
    API_LK_RLKA01,
    API_LK_ILKA01,
    API_LK_ULKA01,
    API_LK_DLKA01,
    API_LK_ILK01,
    API_LK_ULK01,
    API_LK_DLK01,
    API_LK_ULK02,
    API_LK_RLA01,
    API_LK_RLAR01,
    API_LK_ULA01,
    API_LK_RLK02,
    API_LK_ULKA02,
    API_LK_ULKA03,
    API_LK_ULK03
  } from '@/api/constant'
  import { handleResponse } from '@/api/handle-response'
  import { requestOptions } from '@/api/request-options'
    
  function updateLockerInventory(data) {
    return fetch(ROOT + API_LK_ULA01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function ActiveLockerArea(data) {
    return fetch(ROOT + API_LK_ULKA03, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function InactiveLockerArea(data) {
    return fetch(ROOT + API_LK_ULKA02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function updatePlanLocker(data) {
    return fetch(ROOT + API_LK_ULK03, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function GetListPlanMaintance(data) {
    return fetch(ROOT + API_LK_RLK02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function getListLockerGroup(data) {
    return fetch(ROOT + API_LK_RLKA01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function getListReason(data) {
    return fetch(ROOT + API_LK_RLAR01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function createLockerGroup(data) {
    return fetch(ROOT + API_LK_ILKA01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function updateLockerGroup(data) {
    return fetch(ROOT + API_LK_ULKA01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function deleteLockerGroup(data) {
    return fetch(ROOT + API_LK_DLKA01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function createLocker_ILK01(data) {
    return fetch(ROOT + API_LK_ILK01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function updateLocker_ULK01(data) {
    return fetch(ROOT + API_LK_ULK01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function deleteLocker_DLK01(data) {
    return fetch(ROOT + API_LK_DLK01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function UpdateStatus_ULK02(data) {
    return fetch(ROOT + API_LK_ULK02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function getListLocker_RLA01(data) {
    return fetch(ROOT + API_LK_RLA01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  export const lockerSetting = {
    getListLockerGroup,
    createLockerGroup,
    updateLockerGroup,
    deleteLockerGroup,
    createLocker_ILK01,
    updateLocker_ULK01,
    deleteLocker_DLK01,
    UpdateStatus_ULK02,
    getListLocker_RLA01,
    getListReason,
    updateLockerInventory,
    GetListPlanMaintance,
    ActiveLockerArea,
    InactiveLockerArea,
    updatePlanLocker
  }
  