<template>
    <div>
        <b-modal
            id="modal-locker-add-plan-maintenance"
            size="sm"
            centered
            hide-header
            v-model="isOpen"
            :no-close-on-backdrop="true"
        >
        <b-card-header class="banned-background-color-primary">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0 text-primary">
              {{ $t('golf_update_add_plan_maintenance') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-body>
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="$t('golf_locker_date_start_maintenance')" label-cols="5">
                        <date-range-picker
                            v-model="dataUpdate.FromDate"
                            ref="picker"
                            :single-date-picker="true"
                            :ranges="false"
                            opens="right"
                            :time-picker="true"
                            :time-picker-increment="1"
                            :show-dropdowns="true"
                            :auto-apply="true"
                            :locale-data="{
                                format: 'mm/dd/YYYY',
                                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                firstDay: 0
                            }"
                            />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('golf_locker_date_finish_maintenance')" label-cols="5">
                        <date-range-picker
                            v-model="dataUpdate.ToDate"
                            ref="picker"
                            :single-date-picker="true"
                            :ranges="false"
                            opens="right"
                            :time-picker="true"
                            :time-picker-increment="1"
                            :show-dropdowns="true"
                            :auto-apply="true"
                            :locale-data="{
                                format: 'mm/dd/YYYY',
                                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                firstDay: 0
                            }"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="12"> 
                    <b-form-textarea v-model="dataUpdate.Note" :placeholder="$t('golf_locker_inventory_input_note')" class="mb-0" rows="3"
                        max-rows="6"/>
                </b-col>
            </b-row>
        </b-card-body>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="md"
                    class="float-right"
                    :disabled="disableButton"
                    @click="save"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                    size="md"
                    variant="none"
                    class="float-right mr-1"
                    @click="hideModal"
                >
                    {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
    </div>
</template>
<script>
import { lockerSetting } from '@/api/locker-setting'

export default {
    props: ['dateSelected', 'lockerSelected'],
    data() {
        return {
            dataUpdate: {
                FromDate:  this.getUTC('start', 'day'),
                ToDate: this.getUTC('end', 'day'),
                LockerId: [],
                Note: null,
                Status: 'MAINTENANCE'
            },
            isOpen: false,
            disableButton: false
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                this.dataUpdate.FromDate = this.dateSelected
                this.dataUpdate.LockerId = this.lockerSelected.map(x => x.LockerId)
            }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-locker-add-plan-maintenance')
        },
        async save() {
            this.disableButton = true
            const body = {
                FromDate: this.dataUpdate.FromDate,
                ToDate: this.dataUpdate.ToDate,
                LockerId: this.dataUpdate.LockerId,
                Note: this.dataUpdate.Note,
                Status: this.dataUpdate.Status
            }
            await lockerSetting.UpdateStatus_ULK02(body).then(res => {
                if(res.Status == 200) {
                    this.$emit('event', { type: 'after-update-status-locker' })
                    this.hideModal()
                } else {
                    this.showResToast(res)
                }
                this.disableButton = false
            })
        }
    },
}
</script>
<style lang="scss">
    
</style>