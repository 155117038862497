import { lockerSetting } from '@/api/locker-setting'
import LockerUpdateNotAchieved from './modal/LockerUpdateNotAchieved.vue'
import LockerUpdateAchieved from './modal/LockerUpdateAchieved.vue'
export default {
    components: {
        LockerUpdateNotAchieved,
        LockerUpdateAchieved
    },
    data() {
        return {
            listLockerArea: [],
            search: {
                Date: this.getUTC('start', 'day'),
                LockerAreaId: [],
            },
            listLocker: [],
            isLoading: false,
            listLockerSelect: [],
            listAuditStatic: []
        }
    },
    methods: {
        async getListLockerGroup_RLKA01() {
            const body  = {
                Date: this.getUTC('start', 'day'),
                LockerAreaId: null,
                LockerCode: null,
                ZoneId: [],
            }
            await lockerSetting.getListLockerGroup(body).then(res => {
                if(res.Status == 200) {
                    this.listLockerArea = res.Data.LockerArea
                }
            })
        },
        async getListLocker_RLA01() {
            this.isLoading = true
            const body = {
                ...this.search
            }
            await lockerSetting.getListLocker_RLA01(body).then(res => {
                if(res.Status == 200) {
                    res.Data.LockerAudit.forEach(x => {
                        x.check = false
                    });
                    this.listLocker = res.Data.LockerAudit
                    this.listAuditStatic = res.Data.LockerAuditStatistics
                    this.listLockerSelect = []
                }
                this.isLoading = false

            })
        },
        openModalUpdateNotAchieved() {
            this.$bvModal.show('modal-locker-not-achieved')
        },
        openModalUpdateAchieved() {
            this.$bvModal.show('modal-locker-achieved')
        },
        selectLocker(index) {
            this.listLocker[index].check = !this.listLocker[index].check
            if(this.listLocker[index].check) {
                this.listLockerSelect.push(this.listLocker[index])
            } else {
                this.listLockerSelect.splice(this.listLockerSelect.indexOf(this.listLocker[index]),1)
            }
        },
        handEvent(data) {
            if(data.type == 'after-update_not_achieved') {
                this.getListLocker_RLA01()
            }
            if(data.type == 'after-update_achieved') {
                this.getListLocker_RLA01()
            }
        },
        checkAll(check) {
            if(check) {
                this.listLocker.forEach(x => {
                    x.check = true
                })
            } else {
                this.listLocker.forEach(x => {
                    x.check = false
                })
            }
        }
    },
    created() {
        this.getListLockerGroup_RLKA01()
        this.getListLocker_RLA01()
    },
}