<template>
    <div>
        <b-modal
            id="modal-locker-achieved"
            size="sm"
            v-model="isOpen"
            centered
            hide-header
            :no-close-on-backdrop="true"
        >
        <b-card-header class="banned-background">
          <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0 text-success">
              {{ $t('golf_update_locker_achieved') }}
            </h4>
            <ez-icon
              icon="ezGolf-icon-x"
              class="cursor-pointer"
              size="24"
              color="rgba(41, 184, 105, 1)"
              @click="hideModal"
            />
          </div>
        </b-card-header>
        <b-card-text>
            <div style="padding: 20px 16px">
                {{ $t('golf_update_locker_confirm_achieved') }}
            </div>
        </b-card-text>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="success"
                    size="md"
                    class="float-right"
                    @click="updateLocker"
                >
                    {{ $t('golf_common_confirm') }}
                </b-button>
                <b-button
                size="md"
                variant="none"
                class="float-right mr-1"
                @click="hideModal"
                >
                    {{ $t('golf_common_back') }}
                </b-button>
            
            </div>
        </template>
        </b-modal>
    </div>
</template>
<script>
import { lockerSetting } from '@/api/locker-setting'

export default {
    props: ['dateSelect', 'lockerSelect'],
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-locker-achieved')
        },
        async updateLocker() {
            const body = {
                AuditDate: this.dateSelect,
                LockerAuditResultId: [],
                LockerId: this.lockerSelect.map(x => x.LockerId),
                Note: null,
            }
            await lockerSetting.updateLockerInventory(body).then(res => {
                this.showResToast(res)
                    if(res.Status == '200') {
                        this.hideModal()
                        this.$emit('event', { type: 'after-update_achieved' })
                    }
            })
        }
    },
}
</script>
<style lang="scss">
    .banned-background {
        background-color: rgba(40, 199, 111, 0.08);
    }
</style>